<template>
  <section class="result-wrapper">
    <div :class="productClass" class="banner home">
      <div class="container">
        <h1 class="text-center">{{ t('home.title') }}</h1>
        <div class="blocks">
        <div :class="productClass" class="row home-products">
          <div class="d-flex">
            <nuxt-link v-for="(menu, index) in productMenu" :key="index" :to="menu.path">
            <div class="card">
              <h2>{{ menu.name }}</h2>
              <p>{{ menu.desc }}</p>
              <img :src="menu.img">
            </div>
          </nuxt-link>
        </div>
        </div>
      </div>
      </div>
    </div>
    <div class="partner">
      <div class="container">
        <h3>{{ t('loan.partner') }}</h3>
        <div class="logos">
        <VueSlickCarousel v-bind="sliderSetting">
          <div v-for="(logo, index) in logos" :key="index">
            <a href="#" target="blank">
              <img :src="logo">
            </a>
          </div>
        </VueSlickCarousel>
        </div>
      </div>
    </div>
    <div class="application">
      <div class="container">
        <h3>{{ t('loan.apply_title') }}</h3>
        <div class="row">
        <div class="col-sm-6  align-self-center">
          <ul class="steps">
          <li>
            <i>1</i>
            <div v-html="t('loan.apply_step1')" />
          </li>
          <li>
            <i>2</i>
            <div v-html="t('loan.apply_step2')" />
          </li>
          <li>
            <i>3</i>
            <div v-html="t('loan.apply_step3')" />
          </li>
        </ul>
        </div>
        <div class="col-sm-6">
              <div class="loan-period-image">
                <img src="/img/home/home-4.png" alt="">
              </div>
            </div>
      </div>
      </div>
    </div>
    <div class="faq">
      <div class="container">
        <h3 class="mb-2">
{{ t('common.qa') }}
</h3>
        <div id="accordion" class="accordion">
            <div v-for="(item, index) in faqList" :key="index" class="card">
              <div :id="'item' + index" class="card-header">
                <h2 class="mb-0">
                  <button
                    :data-target="'#content' + index"
                    :class="{'collapsed':index}"
                    class="btn btn-link btn-block text-left"
                    type="button"
                    data-toggle="collapse"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    >
                     {{ item.question }}
                  </button>
                </h2>
              </div>
              <div :id="'content' + index" :aria-labelledby="'item' + index" :class="{'show': !index}" class="collapse" data-parent="#accordion">
                <div v-html="item.answer" class="card-body" />
              </div>
            </div>
          </div>
      </div>
    </div>
    </section>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel';
import commonFunc from '../assets/js/commonHelper';
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  components: {
    VueSlickCarousel
  },
  data: function () {
    return {
      logos: [],
      list: [],
      faqList: [],
      sliderSetting: {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        rows: 2,
        slidesPerRow: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              rows: 3,
            }
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              rows: 4,
            }
          },
        ]
      }
    };
  },
  layout(context) {
    return "default";
  },
  head() {
    return {
      ...commonFunc.getSeoMeta(this.$store.state.layout.seo),
      bodyAttrs: {
        class: 'home-page'
      }
    }
  },
  computed: {
    productClass() {
      return 'items-' + this.productMenu.length
    },
    productMenu() {
      const menus = []
      const excluded = ['/blog']
      this.$store.state.layout.menu.forEach((item) => {
        if (!excluded.includes(item.path)) {
          const id = item.path.replace("/", "")
          item.desc = this.t("menu.desc_" + id)
          item.name = this.t("menu." + id)
          item.img = "/img/icons/icon-" + id + '.svg'
          menus.push(item)
        }
      })
      return menus
    }
  },
  async asyncData({ store, route }) {
    await store.dispatch("search/fetchCompanies", { type: "loan" });
    const companies = JSON.parse(JSON.stringify(store.state.search.companies))
    const companyLogos = []
    companies.forEach((item) => {
      companyLogos.push(item?.signLogoUrl)
    })
    let fqaList = []
    await store.dispatch("query/fetchList");
    const data = JSON.parse(JSON.stringify(store.state.query.list));
    fqaList = data.records
    await store.dispatch("layout/fetchSeo", {
      path: route.path
    });
    return { logos: companyLogos, faqList: fqaList }
  },
  mounted() {
  },
  methods: {
  },
};
</script>